import React from 'react';
import './styles.scss';

export default function ProgressCircular(props) {
    let {stroke, progress, text} = props,
        normalizeRadius = 75 - (stroke * 2),
        circumference = (normalizeRadius * 2) * Math.PI,
        strokeDashoffset = circumference - ((progress / 100) * circumference);

    return (
        <svg
            fill="none"
            viewBox="0 0 150 150"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="75"
                cy="75"
                fill="transparent"
                r={normalizeRadius}
                stroke="var(--mdc-theme-secondary)"
                strokeWidth={stroke} />
            <circle
                cx="75"
                cy="75"
                fill="transparent"
                r={normalizeRadius}
                stroke="var(--mdc-theme-primary)"
                strokeDasharray= {circumference + ' ' + circumference}
                strokeWidth={stroke}
                style={{strokeDashoffset}} />
            <text className="text" dy=".3em" fill="var(--mdc-theme-on-surface)" fontWeight="normal"
                textAnchor="middle" x="50%"
                y="50%">{text || ''}</text>
        </svg>
    );
}
