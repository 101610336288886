import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {Elevation} from '@rmwc/elevation';
import SearchSelect from '../storybook/search-select';
import translations from '../../lib/translations';
import rewards from '../../globals/rewards';
import currency from '../../globals/currency';
import formatNumber from '../../lib/helpers/number-format';
import minutesFormat from '../../lib/helpers/minutes-format';

const STAKING_REWARD = 2.28,
    MN_REWARD = 0.914,
    TOTAL_BLOCK_REWARD = 13.7,
    SELECT_COLS = 4, // from searchselect, do not change
    MAX_SELECT_ROWS = 10; // may be changed

const MINUTES_PER_DAY = 1440,
    MIN_PER_YEAR = 365 * MINUTES_PER_DAY; // not more often than once an hour

const RewardCalculatorSummary = props => {
    let
        options,
        stakeValue,
        masternodeValue,
        warning,
        masternodeDigit,
        stakeDigit,
        annualMasternodePercentage,
        annualMasternodeRewards,
        monthlyMasternodeRewards,
        dailyMasternodeRewards,
        weeklyMasternodeRewards,
        totalDailyRewards,
        totalWeeklyRewards,
        totalMonthlyRewards,
        annualStakingPercentage,
        annualStakingRewards,
        monthlyStakingRewards,
        dailyStakingRewards,
        weeklyStakingRewards,
        stakingPayoutCycletimeMinutes,
        masternodesNeeded,
        locales = props.pageContext.langInfo && props.pageContext.langInfo.locales;

    const
        [amountList, setList] = useState([]),
        [masternode, setMasternode] = useState(Math.round(props.amount * 1000) / 1000),
        [stake, setStake] = useState(''),
        allrewards = useStore(rewards.$store),
        rates = useStore(currency.$store),
        translate = translations.init(props);

    const generateMasternodeListAmounts = (masternodeVal = null) => {
        const amount = props.amount,
            additionPerLoop = 1000;

        let arrayOfAmounts = Array.from({length: Math.floor(amount / additionPerLoop) + 1}, (_, i) => i * additionPerLoop);

        arrayOfAmounts = arrayOfAmounts.reverse();

        masternodeValue = (typeof masternodeVal === 'number') ? masternodeVal : Math.max(...arrayOfAmounts);
        setMasternode(masternodeValue);
        setList(arrayOfAmounts);

        stakeValue = (masternodeValue !== 0) ? amount - masternodeValue : amount;
        setStake(stakeValue);
    };

    useEffect(() => {
        currency.on.fetchRate();
        generateMasternodeListAmounts();
        return () => {
            currency.on.stopFetching();
        };
    }, [props.amount]);


    /* ********************************************************************************************************** */
    /* ********************************************************************************************************** */

    /**
    * Based on NRG to USD conversion rate in global store, it calculates
    * equivalent value of supplied NRG to USD
    *
    * @method nrgToUsdConversion
    * @protected
    * @return {Object} The actual state
    * @since 0.8.3
    */
    const nrgToUsdConversion = (nrg) => {
        if (!rates.usd) {
            return;
        }
        let usdValue = rates.usd * nrg;
        usdValue = formatNumber(locales, usdValue, 2, true, true, 3);
        return (
            <small>${usdValue} USD</small>
        );
    };

    if (amountList.length !== 0) {
        options = amountList;
    }

    masternodesNeeded = Math.ceil(masternode / 100000);
    if (masternodesNeeded  > 1) {
        warning = (
            <p className="warning">{translate.t('MIN_MN_NEEDED', false, {nr: masternodesNeeded})}</p>
        );
    }

    if (masternode !== '') {
        masternodeDigit = (
            <p>{formatNumber(locales, masternode, 0, false, true, 0)} NRG</p>
        );
    }

    if (stake !== '') {
        stakeDigit = (
            <p>{formatNumber(locales, stake)} NRG</p>
        );
    }

    // About masternodeReward: note that we need "ether" but need to devide by 1000 for every 1000 cllateral gets a reward.
    // Therefore we transfer to "mili" instead of "ether"
    annualMasternodePercentage = allrewards.masternode;
    annualMasternodeRewards = annualMasternodePercentage * masternode / 100;
    monthlyMasternodeRewards = annualMasternodeRewards / 12;
    dailyMasternodeRewards = annualMasternodeRewards / 365;
    weeklyMasternodeRewards = dailyMasternodeRewards * 7;
    annualStakingPercentage = allrewards.staking;
    annualStakingRewards = annualStakingPercentage * stake / 100;
    monthlyStakingRewards = annualStakingRewards / 12;
    dailyStakingRewards = annualStakingRewards / 365;
    weeklyStakingRewards = dailyStakingRewards * 7;
    totalDailyRewards = dailyMasternodeRewards + dailyStakingRewards;
    totalWeeklyRewards = weeklyMasternodeRewards + weeklyStakingRewards;
    totalMonthlyRewards = monthlyMasternodeRewards + monthlyStakingRewards;

    if (stake && (annualStakingRewards !== 0)) {
        stakingPayoutCycletimeMinutes = Math.round((STAKING_REWARD / annualStakingRewards) * MIN_PER_YEAR);
    }

    return (
        <div className="reward-summary">
            <div className="basic-summary">
                <Elevation>
                    <small>{translate.t('TOTAL_BLOCK_REWARD', true)}</small>
                    <p>{formatNumber(locales, TOTAL_BLOCK_REWARD, 2)} NRG</p>
                </Elevation>
                <Elevation>
                    <small>{translate.t('CALCULATION_REWARD', true)}</small>
                    <p>{formatNumber(locales, props.amount, 1, false, true, 0)} NRG</p>
                </Elevation>
                <Elevation>
                    <small>{translate.t('MASTERNODE_BLOCK_REWARD', true)}</small>
                    <p>{formatNumber(locales, 10 * MN_REWARD)} NRG</p>
                </Elevation>
                <Elevation>
                    <small>{translate.t('POS_BLOCK_REWARD', true)}</small>
                    <p>{formatNumber(locales, STAKING_REWARD)} NRG</p>
                </Elevation>
            </div>

            <div className="estimated-masternodereward">
                <p>{translate.t('ESTIMATED_MASTERNODE_REWARD', true)}</p>

                <div className="select-wrapper">
                    <SearchSelect
                        className="select"
                        disabled={props.amount < 1000}
                        maxItems={MAX_SELECT_ROWS * SELECT_COLS}
                        onChange={generateMasternodeListAmounts}
                        options={options}
                        value={masternode.toString()}/>
                </div>
                {warning}

                <div className="pure-g values">
                    <div className="pure-u-1-3">
                        <small>{translate.t('MASTERNODE_COLLATERAL', true)}</small>
                        {masternodeDigit}
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('INTERVAL', true)} [{translate.t('HOURS', true)}]</small>
                        <p>{minutesFormat(allrewards.payoutCycleTime)}</p>
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('INTERVAL_REWARD', true)}</small>
                        <p>{formatNumber(locales, masternode * MN_REWARD / 1000)} NRG</p>
                    </div>
                </div>
                <div className="pure-g values">
                    <div className="pure-u-1-3">
                        <small>{translate.t('DAILY_REWARD', true)}</small>
                        <p>{formatNumber(locales, dailyMasternodeRewards, 1, false, true, 3)} NRG</p>
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('WEEKLY_REWARD', true)}</small>
                        <p>{formatNumber(locales, weeklyMasternodeRewards, 1, false, true, 3)} NRG</p>
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('MONTHLY_REWARD', true)}</small>
                        <p>{formatNumber(locales, monthlyMasternodeRewards, 1, false, true, 3)} NRG</p>
                    </div>
                </div>
            </div>

            <div className="estimated-stakingreward">
                <p>{translate.t('ESTIMATED_STAKING_REWARD', true)}</p>
                <div className="pure-g values">
                    <div className="pure-u-1-3">
                        <small>{translate.t('AMOUNT_STAKED', true)}</small>
                        {stakeDigit}
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('INTERVAL', true)} [{translate.t('HOURS', true)}]</small>
                        <p>{minutesFormat(stakingPayoutCycletimeMinutes)}</p>
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('INTERVAL_REWARD', true)}</small>
                        <p>{formatNumber(locales, STAKING_REWARD)} NRG</p>
                    </div>
                </div>
                <div className="pure-g values">
                    <div className="pure-u-1-3">
                        <small>{translate.t('DAILY_REWARD', true)}</small>
                        <p>{formatNumber(locales, dailyStakingRewards, 1, false, true, 3)} NRG</p>
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('WEEKLY_REWARD', true)}</small>
                        <p>{formatNumber(locales, weeklyStakingRewards, 1, false, true, 3)} NRG</p>
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('MONTHLY_REWARD', true)}</small>
                        <p>{formatNumber(locales, monthlyStakingRewards, 1, false, true, 3)} NRG</p>
                    </div>
                </div>
            </div>
            <div className="divider"/>
            <div className="total">
                <p>{translate.t('TOTAL', true)}</p>
                <div className="pure-g values">
                    <div className="pure-u-1-3">
                        <small>{translate.t('DAILY_REWARD', true)}</small>
                        <p>{formatNumber(locales, totalDailyRewards, 1, false, true, 3)} NRG</p>
                        {nrgToUsdConversion(totalDailyRewards)}
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('WEEKLY_REWARD', true)}</small>
                        <p>{formatNumber(locales, totalWeeklyRewards, 1, false, true, 3)} NRG</p>
                        {nrgToUsdConversion(totalWeeklyRewards)}
                    </div>
                    <div className="pure-u-1-3">
                        <small>{translate.t('MONTHLY_REWARD', true)}</small>
                        <p>{formatNumber(locales, totalMonthlyRewards, 1, false, true, 3)} NRG</p>
                        {nrgToUsdConversion(totalMonthlyRewards)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RewardCalculatorSummary;
