/* eslint-disable react/no-multi-comp */
/**
 * The SearchSelect component, just as Select Menus and Text Fields is created by using RMWC
 * https://rmwc.io/text-fields
 * https://rmwc.io/layout-grid
 * https://material.io/develop/web/components/input-controls/select-menus/
 */
import React, {Component} from 'react';
import _ from 'underscore';
import {TextField} from '@rmwc/textfield';
import {Grid, GridCell} from '@rmwc/grid';
import './styles.scss';
import {Icon} from '@rmwc/icon';

class SelectMenuSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: this.getSelectedIndex(props),
            options: [],
            query: '',
            isListActived: false
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.handleListClick = this.handleListClick.bind(this);
        this.setSelectWrapperRef = this.setSelectWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleSearchEnter = this.handleSearchEnter.bind(this);
    }

    static defaultProps = {
        value: '',
        options: [],
        label: '',
        onChange: null,
        disabled: false,
        searchBox: true,
        className: ''
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        let newState;
        const instance = this,
            props = instance.props,
            valueChange = (prevProps.value !== props.value),
            optionsChange = !_.isEqual(prevProps.options, props.options);

        if (valueChange || optionsChange) {
            newState = {
                selectedIndex: instance.getSelectedIndex(props)
            };
            if (optionsChange) {
                instance._searchBox && instance._searchBox.reset();
                newState.query = '';
            }
            instance.setState(newState);
        }
    }

    getSelectedIndex = ({value, options}) => {
        return options.findIndex(element => {
            const itemValue = (typeof element.label === 'string') ? element.value : element;
            return itemValue == value; // no tripple check: it is ok to match even if types are different
        });
    }

    handleSearchEnter(search) {
        let itemValue;
        const instance = this;
        const searchUpper = search.toUpperCase();
        const item = instance.props.options.find(option => {
            const itemLabel = (typeof option.label === 'string') ? option.label : option;
            return itemLabel.toString().toUpperCase() === searchUpper;
        });
        if (item !== undefined) {
            itemValue = (typeof item.label === 'string') ? item.value : item;
            instance.handleItemClick(itemValue);
        }
    }

    setSelectWrapperRef(node) {
        this.wrapperRef = node;
    }

    displayCustomSelectMenu = () => {
        const instance = this;
        if (!instance.props.options) {
            return <div>Not found</div>;
        }
        const {className, disabled, maxItems, searchBox} = instance.props,
            {selectedIndex, isListActived, query} = instance.state,
            selectedOption = instance.props.options[selectedIndex],
            firstItems = Math.round(maxItems / 2),
            lastItems = maxItems - firstItems,
            queryUpper = query.toUpperCase();

        let options = [...instance.props.options],
            minArrays, maxArrays, selectedLabel, searchBoxElement;

        options = options.filter(option => {
            const itemLabel = (typeof option.label === 'string') ? option.label : option;
            return itemLabel.toString().toUpperCase().indexOf(queryUpper) > -1;
        });

        if (maxItems && (options.length > maxItems)) {
            minArrays = options.splice(0, lastItems);
            maxArrays = options.splice(-lastItems);
            options = minArrays.concat(maxArrays);
        }

        const items = options.map((option, index) => {
            let itemValue, itemLabel, isSelected;
            const selectedItem = instance.props.options[instance.state.selectedIndex];
            if (typeof option.label === 'string') {
                itemValue = option.value;
                itemLabel = option.label;
                isSelected = !!selectedItem && (instance.props.options[instance.state.selectedIndex].value === itemValue);
            }
            else {
                itemValue = option;
                itemLabel = option;
                isSelected = !!selectedItem && (instance.props.options[instance.state.selectedIndex] === itemValue);
            }
            return (
                <GridCell
                    className={`mdc-ripple-upgraded mdc-list-item${isSelected ? ' mdc-list-item--activated' : ''}`}
                    desktop={3}
                    key={index}
                    onClick={instance.handleItemClick.bind(instance, itemValue)}
                    phone={4}
                    role="menuitem"
                    tablet={4}>
                    {itemLabel}
                </GridCell>);
        });

        if (selectedOption !== undefined) {
            selectedLabel = (typeof selectedOption.label === 'string') ? selectedOption.label : selectedOption;
        }

        if (searchBox) {
            searchBoxElement = (
                <SearchBox
                    callbackQuery={instance.handleSearch}
                    className="theme-mdc-search-box theme-mdc-select-full-width"
                    onSelect={instance.handleSearchEnter}
                    placeholder="Search amount"
                    ref={inst => instance._searchBox=inst} />
            );
        }
        else {
            instance._searchBox = null;
        }

        return (
            <div className="theme-mdc-select-wrap theme-mdc-select-full-width" ref={instance.setSelectWrapperRef} role="listbox">
                <div
                    className={`${className? className+' ':''}mdc-select theme-mdc-select${disabled? ' mdc-select--disabled':''} theme-mdc-select-full-width${isListActived? ' mdc-select--activated': ''}`}
                    tabIndex={-1}>
                    <div className="mdc-select__anchor theme-mdc-select-full-width">
                        <i className="mdc-select__dropdown-icon" />
                        <div className="mdc-select__selected-text" onClick={instance.handleListClick}>{selectedLabel}</div>
                    </div>
                </div>
                <div className={`theme-mdc-menu theme-mdc-select-full-width mdc-menu mdc-select__menu mdc-menu-surface${isListActived? ' mdc-menu-surface--open': ''}`}>
                    {searchBoxElement}
                    <Grid aria-hidden="true" aria-orientation="vertical" className="mdc-list theme-mdc-list mdc-menu__items" role="menu"
                        tabIndex="-1">
                        {items}
                    </Grid>
                </div>
            </div>
        );
    }

    handleListClick = () => {
        this.setState({isListActived:!this.state.isListActived});
    }

    handleItemClick = itemValue => {
        const instance = this;
        const onChange = instance.props.onChange;
        let searchProps;
        let newState = {
            isListActived: false,
            query: '',
        };

        if (!onChange) {
            searchProps = {
                value: itemValue,
                options: instance.props.options
            };
            newState.selectedIndex = instance.getSelectedIndex(searchProps); // handle internally
        }
        instance.setState(newState, () => {
            instance._searchBox && instance._searchBox.reset();
            onChange && onChange(itemValue);
        });
    }

    handleClickOutside = (e) => {
        if (this.state.isListActived && this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.handleListClick();
        }
    }

    handleSearch = (query, close) => {
        const newState = {
            query: query
        };
        if (close) {
            newState.isListActived = false; // close the dropdown
        }
        this.setState(newState);
    }

    render() {
        return (
            this.displayCustomSelectMenu()
        );
    }
}

class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
        };
        this.timeout = null;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    reset() {
        this.setState({query: ''});
    }

    handleInputChange = e => {
        const query = e.target.value;
        this.setState({
            query
        }, () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.props.callbackQuery(query), 250);
        });
    }

    handleKeyPress = e => {
        const key = e.charCode;
        if (key === 13) {
            // enter pressed -> if there is a matching entry, the immediately select it
            clearTimeout(this.timeout);
            this.props.onSelect(this.state.query);
        }
    }

    render() {
        return (
            <div className="inputs">
                <TextField
                    className={this.props.className}
                    icon={{
                        icon: 'keyboard_backspace',
                        tabIndex: 0,
                        onClick: () => {
                            this.setState({query: ''});
                            this.props.callbackQuery('', true);
                        }
                    }}
                    label={this.props.placeholder}
                    name="search"
                    onChange={this.handleInputChange}
                    onKeyPress={this.handleKeyPress}
                    outlined
                    trailingIcon="search"
                    value={this.state.query} />
                <Icon className="close" icon = {{
                    icon: 'close',
                    tabIndex: 0,
                    onClick: () => {
                        this.setState({query: ''});
                        this.props.callbackQuery('', true);
                    }
                }} />
            </div>
        );
    }
}

export default SelectMenuSearch;
