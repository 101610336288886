import {createStore, createEffect} from 'effector';
import {delay} from 'itsa-utils';
import axios from 'axios';
import {explorerUrl} from '../lib/helpers/network-providers';

const $store = createStore({
        usd: null
    }),
    DELAY = 60000;
let polling = false;

/**
* Initiates a next pollBlockNumber after a delay of 10 seconds.
*
* @method nextPoll
* @protected
* @since 0.4.0
*/
const nextPoll = async () => {
    await delay(DELAY);
    polling && fetchRate();
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* Fetches current NRG to USD rate
*
* @method nrgToUsd
* @protected
* @return {Object} The actual state
* @since 0.8.3
*/
const nrgToUsd = async () => {
    try {
        let response = await axios.post(explorerUrl + '/api', {
            module: 'stats',
            action: 'ethprice'
        });
        return response.data.result;
    }
    catch (err) {
        throw new Error(err);
    }
};

const fetchRate =  createEffect({
    async handler() {
        const rate = await nrgToUsd();
        polling = true;
        nextPoll();
        return {
            usd: rate.ethusd
        };
    }
});

const stopFetching = () => {
    polling = false;
};

fetchRate.fail.watch(({error, params}) => {
    console.error(params);
    console.error(error);
});

$store.on(fetchRate.done, (store, {result}) => {
    return result;
});

const on = {
    fetchRate,
    stopFetching
};

const rates = {
    $store,
    on
};

export default rates;
